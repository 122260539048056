import React from 'react';
import ArtPiece from "./ArtPiece";

function Art(){
    return(
        <div>

            <ArtPiece image={require("../images/Cloak.jpg")}
                      alt="image of Cloak"
                      name="Cloak"/>
            <ArtPiece image={require("../images/Jalorm.jpg")}
                      alt="Image of Joram"
                      name="Joram"/>
            <ArtPiece image={require("../images/Maryann P.jpg")}
                      alt="Image of Maryann"
                      name="Maryann"/>
            <ArtPiece image={require("../images/episode1art.jpg")}
                      alt="Episode 1 image"
                      name="Slaver's Soup"/>

        </div>
    );
}
export default Art;