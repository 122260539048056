import React from 'react';


function Story(){
    return(
        <div>

        </div>
    );
}
export default Story;