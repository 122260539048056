import React from 'react';


function FullCharCard(props){
    return(
        <div className="padded short-char-card">
            <h3>{props.name}</h3>
            <div className="img-title">
                <img src={props.image} className="portrait"/>
                <h5 className="gap"> {props.descrip}</h5>
            </div>


        </div>
    );
}
export default FullCharCard;