import React from 'react';


function Contact(){

    const [nameErrorMsg, nameErrorSetter] = React.useState('');
    const [emailErrorMsg, emailErrorSetter] = React.useState('');
    const [subjectErrorMsg, subjectErrorSetter] = React.useState('');
    const [messageErrorMsg, messageErrorSetter] = React.useState('');
    const [thankMsg, thankSetter] = React.useState('');

    function nameChange(){
        nameErrorSetter('')
    }
    function emailChange(){
        emailErrorSetter('')
    }
    function subjectChange(){
        subjectErrorSetter('')
    }
    function messageChange(){
        messageErrorSetter('')
    }

    function handleSubmit(event) {
        let canSend = true;
        event.preventDefault()
        if(event.target.elements.name.value === ''){
            nameErrorSetter('no name found!');
            canSend = false;
        }
        if(event.target.elements.email.value === ''){
            emailErrorSetter('no email found!');
            canSend = false;
        }
        if(!event.target.elements.email.value.includes('@')){
            emailErrorSetter('email not valid!');
            canSend = false;
        }
        if(event.target.elements.subj.value === ''){
            subjectErrorSetter('no subject found!');
            canSend = false;
        }
        if(event.target.elements.message.value === ''){
            messageErrorSetter('no message found!');
            canSend = false;
        }
        if(canSend){
            console.log('send the message to aws');
            const contactInfo = {
                name: event.target.elements.name.value,
                email: event.target.elements.email.value,
                subject: event.target.elements.subj.value,
                message: event.target.elements.message.value
            };
            window
                .fetch('https://zovhde8by5.execute-api.us-east-1.amazonaws.com/prod/contact',
                    {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                        },
                    body: JSON.stringify(contactInfo),
                    })
            thankSetter('Thank you for your message!');
            event.target.elements.name.value = '';
            event.target.elements.email.value = '';
            event.target.elements.subj.value ='';
            event.target.elements.message.value ='';
        }
    }

    return(
        <div className="content-from-top form-center" >
            <form onSubmit={handleSubmit}>
                <ul className="form-list">
                    <li><label className="form-label" htmlFor='nameInput'>Name:</label></li>
                    <li><input className="long-input" id='nameInput' type="text" name="name" onChange={nameChange}/></li>
                    <li><div className="errorMsg">{nameErrorMsg}</div></li>
                    <li><label className="form-label" htmlFor='emailInput'>Email:</label></li>
                    <li><input className="long-input" id='emailInput' type="text" name="email" onChange={emailChange} /></li>
                    <li><div className="errorMsg">{emailErrorMsg}</div></li>
                    <li><label className="form-label" htmlFor='subjectInput'>Subject:</label></li>
                    <li><input className="long-input" id='subjectInput' type="text" name="subj" onChange={subjectChange} /></li>
                    <li><div className="errorMsg">{subjectErrorMsg}</div></li>
                    <li><label className="form-label" htmlFor='messageInput'>Message:</label></li>
                    <li><textarea className="msg-input" id='messageInput' type="text" name="message" onChange={messageChange} /></li>
                    <li><div className="errorMsg">{messageErrorMsg}</div></li>
                </ul>
                <button type="submit" >Submit</button>
            </form>
            <div className="thankmsg">{thankMsg}</div>
        </div>
    );
}
export default Contact;