import React from 'react';
import FullCharCard from "./FullCharCard.js";


function Characters(){
    return(
        <div className="padded">
            <FullCharCard name="Bimpnotten Tippletoe Lily Veloptima Fapplestamp (aka Cloak)"
                           image={require("../images/Cloak.jpg")}
                           descrip="A love of learning has driven this forest gnome to search for hidden knowledge.  Her patron has given clues via visions and dreams that have led her to the deep deserts containing the ruins of an ancient civilization.  Will her reckless pursuit of knowledge awaken a long forgotten evil and endanger the world or will it finally vanquish the threat permanently?"/>
            <FullCharCard name="Maryann"
                           image={require("../images/Maryann P.jpg")}
                          descrip="Cast aside by a village on the edge of hostile elven territory, Maryann has still not lost her kind heart.  Always wanting to discover the story and reasons behind actions, she uses her music to heal others and salve the unseen wounds."/>
            <FullCharCard name="Joram"
                           image={require("../images/Jalorm.jpg")}
                          descrip="Driven to prove himself, Joram thinks he is the leader of the trio but is often more lost than he wants to appear to be.  This young sorcerer is out to prove his worth to the world and beware the opponent who stands in his way, for Joram will always strike first."/>
        </div>
    );
}
export default Characters;