
import React from 'react';


class EpisodeCard extends React.Component{
    constructor(props) {
        super(props);

    };

    render(){
        console.log(this.props);
        return(
            <div className="episode-card">
                <div className="img-title w3-center ">
                    <div className="episode-card-img ">
                        <img src={this.props.image} className="thumbnail"/>
                    </div>

                        <div className="episode-card-info ">
                            <div className="vert-center">
                                <h3 >{this.props.episode}</h3>
                                <h5> {this.props.title}</h5>
                            </div>
                        </div>


                </div>
                <audio controls className="w3-center">
                    <source src={this.props.audio} type="audio/mpeg"/>

                </audio>
            </div>

        );
    }
}
export default EpisodeCard;



