import React from 'react';
import ArtPiece from "./ArtPiece";

function Maps(){
    return(
        <div>
            <ArtPiece image={require("../images/Sandhaven.png")}
                      alt="Map of sandhaven"
                      name="Sandhaven"/>
            <ArtPiece image={require("../images/SandhavenCliff.png")}
                      alt="Map of Sandhaven's cave area"
                      name="Sandhaven Cave City"/>
        </div>
    );
}
export default Maps;