import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import './images/index.css';
import Site from './Comps/Site';

ReactDOM.render(
    <Router>
        <Site />
    </Router>,
    document.getElementById('root')
);


