
import React from 'react';
import Home from './Home.js';
import NavBar from './NavBar.js';
import Episodes from './Episodes.js';
import Characters from './Characters.js';
import Contact from './Contact.js';
import Maps from './Maps.js';
import Journal from './Journal.js';
import Art from './Art.js';
import Story from './Story.js';
import CommunityArt from './CommunityArt.js';
import NoMatch from './NoMatch.js';
import Footer from './Footer.js';

import {Route, Switch, Redirect} from 'react-router-dom';

function Site(){
    return(
        <div className="app-main">
            <div className="site-content">
                <NavBar/>
                <Switch>
                    <Route exact path="/Home" component={Home}/>
                    <Route exact path="/">
                        <Redirect to="/Home"/>
                    </Route>
                    <Route exact path="/Episodes" component={Episodes}/>
                    <Route exact path="/Characters" component={Characters}/>
                    <Route exact path="/Contact" component={Contact}/>
                    <Route exact path="/Maps" component={Maps}/>
                    <Route exact path="/Journal" component={Journal}/>
                    <Route exact path="/Art" component={Art}/>
                    <Route exact path="/Story" component={Story}/>
                    <Route exact path="/CommunityArt" component={CommunityArt}/>
                    <Route component={NoMatch}/>
                </Switch>
            </div>
            <Footer />
        </div>
    );
}

export default Site;