import React from 'react';


function NoMatch(){
    return(
        <div>

        </div>
    );
}
export default NoMatch;