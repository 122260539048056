import React from 'react';
import EpisodeCard from "./EpisodeCard";


function Episodes(){
    return(
        <div className="content-from-top content-center">
            <h4 className="w3-padding-48">Episodes are released each Monday.</h4>
            <div className="episode-list">
                <EpisodeCard episode="Episode 1"
                             title="Slaver's soup"
                             image={require("../images/episode1art.jpg")}
                             audio={require("../audio/Episode1FInal1.mp3")}
                />

            </div>
        </div>
    );
}
export default Episodes;