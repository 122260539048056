import React from 'react';
import {Link, withRouter} from 'react-router-dom';




const NavBar = withRouter(({ history }) => (
    <div>
        <div className="w3-top">
            <div className="w3-bar w3-black ">
                <div className="w3-bar-item w3-button w3-padding-large w3-hide-medium w3-hide-large w3-right"
                    onClick={()=>toggleMenu()} title="Toggle Navigation Menu">
                    <i className="fa fa-bars"></i></div>
                <Link to='/Home' className="w3-bar-item w3-button w3-padding-large">Home</Link>
                <div className="w3-right">
                    <Link to='/Episodes' className="w3-bar-item w3-button w3-padding-large w3-hide-small ">Episodes</Link>
                    <Link to='/Characters' className="w3-bar-item w3-button w3-padding-large w3-hide-small ">Characters</Link>
                    <div className="w3-dropdown-hover w3-hide-small">
                        <button className="w3-padding-large w3-button" title="More">
                            Extras
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className="w3-dropdown-content w3-bar-block w3-card-4">
                            <Link to='/Art' className="w3-bar-item w3-button">Art</Link>
                            <Link to='/Journal' className="w3-bar-item w3-button">Journal</Link>
                            <Link to='/Maps' className="w3-bar-item w3-button">Maps</Link>
                            <Link to='/Story' className="w3-bar-item w3-button">Story</Link>
                            <Link to='/CommunityArt' className="w3-bar-item w3-button">Community Art</Link>
                        </div>
                    </div>
                    <Link to='/Contact' className="w3-bar-item w3-button w3-padding-large w3-hide-small">Contact</Link>
                </div>
            </div>
        </div>

        <div id="nav" className="w3-bar-block w3-black w3-hide w3-hide-large w3-hide-medium w3-top marge-top" >
            <Link to='/Episodes' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Episodes</Link>
            <Link to='/Characters' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Characters</Link>
            <Link to='/Art' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Art</Link>
            <Link to='/Journal' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Journal</Link>
            <Link to='/Maps' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Maps</Link>
            <Link to='/Story' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Story</Link>
            <Link to='/CommunityArt' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Community Art</Link>
            <Link to='/Contact' className="w3-bar-item w3-button w3-padding-large" onClick={()=>toggleMenu()}>Contact</Link>
        </div>

        <div className="content-from-top">
            <div className="title-image">
                <div className = "heiro-img"></div>
                <div className="back-logo" ></div>
                <div className = "heiro-img"></div>
            </div>
        </div>


    </div>
    )
);

function toggleMenu() {
    console.log('opening menu button');
    var x = document.getElementById("nav");
    if (x.className.indexOf("w3-show") === -1) {
        x.className += " w3-show";
    } else {
        x.className = x.className.replace(" w3-show", "");
    }
}

export default NavBar;