import React from 'react';
import EpisodeCard from "./EpisodeCard.js";
import ShortCharCard from "./ShortCharCard.js";


function Home(){
    return(
        <div className="content-from-top">
            <div className="w3-container w3-content w3-center w3-padding-64">
                <h2 className="w3-wide">A 5e D&D Podcast</h2>
                <p className="w3-opacity"><i>Welcome to a fun, family friendly romp through the world of Vankal</i></p>
                <div className="content-center w3-padding-64" >
                    <EpisodeCard
                        episode="Episode 1"
                        title="Slaver's soup"
                        image={require("../images/episode1art.jpg")}
                        audio={require("../audio/Episode1FInal1.mp3")}
                    />
                </div>
                <div className="w3-row w3-padding-32">
                    <div className="w3-center"><h3>The Cast</h3></div>
                    <div className="w3-third">
                        <ShortCharCard name="Cloak"
                                        image={require("../images/Cloak.jpg")}
                                        quote="I am not a gomer..."/>


                    </div>
                    <div className="w3-third">
                        <ShortCharCard name="Maryann"
                                       image={require("../images/Maryann P.jpg")}
                                       quote="Oh, a monster.  I cast speak with animals!"/>

                    </div>
                    <div className="w3-third">
                        <ShortCharCard name="Joram"
                                       image={require("../images/Jalorm.jpg")}
                                       quote="Squeaky yellow thunder pants!"/>

                    </div>

                </div>
            </div>

        </div>
    );
}
export default Home;