import React from 'react';


function ShortCharCard(props){
    return(
        <div className="short-char-card w3-center ">


            <div className="short-card-info ">
                <h4 >{props.name}</h4>
                <img src={props.image} className="thumb-port"/>
                <h5> {props.quote}</h5>
            </div>


        </div>
    );
}
export default ShortCharCard;