import React from 'react';


function ArtPiece(props){

    return(
        <div className="responsive">
            <div className="gallery">
                <a target="_blank" href={props.image}>
                    <div className="art-piece">
                        <img className="gal-image" src={props.image} alt={props.alt}/>
                    </div>
                </a>
                <div className="desc">{props.name}</div>
            </div>
        </div>
    );
}
export default ArtPiece;