import React from "react";

function Footer(){
    return(
        <footer className=" w3-center w3-opacity w3-light-grey w3-xlarge ">
            <i className="fa fa-facebook-official w3-hover-opacity">

            </i>
            <a href="https://twitter.com/bardsjournal" target="_blank">
                <i className="fa fa-twitter w3-hover-opacity" />

            </a>

        </footer>

    );
}
export default Footer;